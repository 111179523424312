@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap);
body,html{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

h1,h2{
    font-weight: 400;
    color: #4e6279;
}


#navbar {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 50px calc(100% - 50px);
  grid-template-columns: 240px calc(100% - 240px);
}

#navbar div {
  position: relative;
}
#navbar div .nav-logo-container {
  position: fixed;
  height: 50px;
  width: 240px;
}
#navbar div #nav-logo {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#navbar div #nav-logo img {
  margin-left: 20px;
}
#navbar div #top-nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #2c3e50;
  z-index: 1000;
}

#navbar div #top-nav img {
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 30px;
  color: white;
}

#navbar div #top-nav .button .img {
  margin-top: 5px;
  color: white;
  font-weight: 400;
}
#navbar div #top-nav .button {
  position: absolute;
  right: 30px;
  top: 50%;
  height: 30px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 10px;
}

#navbar div .side-nav-container {
  position: fixed;
  width: 240px;
  height: 100%;
  overflow-y: scroll;
}

#navbar div .side-nav-container::-webkit-scrollbar {
  width: 4px;
}
#navbar div .side-nav-container::-webkit-scrollbar-track {
  background-color: rgba(60, 60, 60, 0.4);
  border-radius: 3px;
}
#navbar div .side-nav-container::-webkit-scrollbar-thumb {
  background-color: #1abc9c;
  border-radius: 3px;
}

#navbar div #side-nav {
  height: 140%;
  width: 100%;
  background-color: #2c3e50;
}

#navbar div #side-nav .side-nav-usr {
  width: 100%;
  margin: 0 auto;
  height: 100px;
  padding: 5px;
  box-sizing: border-box;
}

#navbar div #side-nav .side-nav-usr .user {
  background-color: #3e5771;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  border-top: 3px solid #1abc9c;
  display: grid;
  grid-template-columns: 30% 70%;
}

#navbar div #side-nav .side-nav-usr .user p {
  margin: 4px;
  color: white;
}

#navbar div #side-nav .nav-element {
  cursor: pointer;
  height: 40px;
  width: 100%;
  background-color: #2e2e2e;
  color: white;
  position: relative;
  overflow: hidden;
}
#navbar div #side-nav .nav-element .selected-nav-el {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #e9e9e9;
  z-index: 100;
  -webkit-transform: translate(50%, -50%) rotate(45deg);
          transform: translate(50%, -50%) rotate(45deg);
}
#navbar div #side-nav .nav-element .nav-element-content {
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#navbar div #side-nav .nav-element .nav-element-content p {
  color: white;
}
#navbar div #side-nav .nav-element .nav-element-content img {
  margin-right: 10px;
  margin-top: 4px;
}

#navbar #body {
  box-sizing: content-box;
  background-color: #e9e9e9;
}

:root {
  --primary-color: #3498db;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #e74c3c;
  --success-color: #2ecc71;
}

button {
  display: inline-block;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 5px;
}

.link {
  background: none;
  padding: 0;
  margin: 0;
}

.block {
  display: block;
  width: 100%;
}

.sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.primary {
  background: #3498db;
  background: var(--primary-color);
  color: #fff;
}

.light:hover {
  color: #fff;
  background: #24292e;
}

.light {
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.dark {
  background: #333333;
  background: var(--dark-color);
  color: #fff;
}

.danger {
  background: #e74c3c;
  background: var(--danger-color);
  color: #fff;
}

.success {
  background: #2ecc71;
  background: var(--success-color);
  color: #fff;
}

.white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.Loader,
.Loader:before,
.Loader:after {
  border-radius: 50%;
}
.Loader {
  color: #ac68e3;
  font-size: 7px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 20;
}
.Loader:before,
.Loader:after {
  position: absolute;
  content: "";
}
.Loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.Loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed !important;
  z-index: 500;
  background-color: white;
  width: 300px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 39%;
  top: 40%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (max-width: 600px) {
  .modal {
    left: 30px;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed !important;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.container-header{
    width: 100%;
    height: 70px;
    background-color: #e9e9e9;
    margin-bottom: 20px;
}
.container-header .form-name{
    position: relative;
    height: 100%;
}
.container-header .form-name h2{
    margin-top: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.container-header .breadcrum{
 float: right;  
 margin-right: 20px;

}
.container-header .button-crums .buttons{
    display: flex;
    flex-direction: row;
}
.container-header .button-crums .buttons .refresh{
    display: none;
}
.container-header .button-crums {
    left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width:750px){
    .container-header{
      margin-bottom: 30px;
    }
    .container-header .breadcrum{
        display: none;
     }
    .container-header .button-crums .buttons{
        float: top;
        padding-bottom: 5px;
        display: flex;
    }
    .container-header .button-crums .buttons .refresh{
        display: block;
    }
}
.white-card-parent {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.white-card {
  border-top: 5px solid #1abc9c;
  padding: 20px;
  padding-top: 40px;
  box-shadow: 0px 0px 0px rgba(203, 112, 255, 0.521);
  border-radius: 4px;
  background-color: white;
  min-height: 50vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .white-card-parent {
    padding: 5px;
  }

  .white-card {
    padding: 10px;
    padding-top: 20px;
  }
}

input,
textarea,
select {
  height: 40px;
  border: solid 1px rgb(180, 180, 180);
  border-radius: 3px;
  font-size: 12px;
  padding: 0 1vh;
  width: 15%;
  margin: 10px 0px 20px 0px;
  box-sizing: border-box;
}

input[type="file"] {
  height: 30px;
  border: none;
  margin: 10px 0px 20px -5px;
  font-size: 12px;
  width: 100%;
  padding: 0 1vh;
  box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* border: solid 2px rgb(28, 192, 137); */
  box-shadow: 0px 0px 3px inset rgba(0, 0, 0, 0.3);
}

.wide {
  width: 95%;
}

textarea {
  box-sizing: unset;
}

.login-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: rgb(70, 70, 70);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgb(230, 31, 0) 52%,
    rgb(230, 31, 0) 50%,
    rgb(230, 31, 0) 100%
  );
  align-items: center;
  justify-content: center;
}

.project-title {
  background-color: white;
  border-top: 3px solid #e61f00;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.project-title h1 {
  margin: 20px;
  color: white;
  background-color: deepskyblue;
}
.login-card {
  padding: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 3px solid #e61f00;
  border-radius: 3px;
  width: 350px;
  min-height: 380px;
  background-color: white;
  padding: 10px;
}
.login-logo {
  width: 190px;
  margin-top: 3vh;
  height: auto;
  padding-bottom: 2vh;
}
.login-input-container {
  width: 90%;
  padding-top: 2vh;
  height: 100%;
  border-top: solid 1px rgba(207, 207, 207, 0.664);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 13px;
}
.login-input-container span {
  text-align: left;
}
.login-forgot {
  text-decoration: none;
  font-weight: 700;
  color: rgb(70, 70, 70);
  font-size: 12px;
  /* margin-top:0.2vh; */
}
.login-signin {
  background: rgb(255, 54, 54);
  border: solid 2px rgb(255, 54, 54);
  margin-top: 2vh;
  width: 98%;
  border-radius: 3px;
  font-weight: 500;
  height: 5vh;
  color: white;
  font-size: 2vh;
}
.login-ipt {
  height: 30px;
  border: solid 1px rgb(180, 180, 180);
  border-radius: 3px;
  font-size: 12px;
  padding: 0 1vh;
  width: 94%;
}
.login-ipt:focus {
  outline: none;
  border: solid 2px rgb(28, 192, 137);
}
@media screen and (max-width: 350px) {
  .login-card {
    width: 290px;
    height: 370px;
  }
}

#add-user .add-user-form .fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}
#add-user{
    overflow-x:hidden;
}

#add-user .add-user-form .fields .field{
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
.au-areascontainer{
    width:94%;
    margin:2vh;
    background:rgb(219, 219, 219);
    border-radius:4px;
    display:flex;
    flex-direction:row;
    padding:0.5vh;
    justify-content:flex-start;
    flex-wrap:wrap;
    min-height:2vh;
}
.au-zones{
    margin:2vh 2%;
    width:96%;
    min-height:5vh;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}
.au-allareas{
    width:30%;
    margin:1.5vh 1%;
    background:rgb(255, 255, 255);
    border-radius:4px;
    color:black;
    display:flex;
    flex-direction:column;
    min-height:10vh;
}
.au-area-head{
    width:98%;
    min-height:2.5vh;
    padding: 1%;
    background:#25a25a;
    color:white;
}
.au-text{
    margin:2vh 1%;
}
@media  screen and (max-width:850px){
    #add-user .add-user-form .fields{
        grid-template-columns: 1fr
    }
    .au-allareas{
        width:48%;
    }
}
@media  screen and (max-width:600px){
    .au-allareas{
        width:98%;
    }
}

.sortedTable,
th,
td {
  table-layout: auto;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  padding: 15px;
}
.sortedTable-header {
  background-color: #2c2c2c;
  color: white;
  border-right: 1px solid white;
  cursor: pointer;
}

.sortedTable .redRow {
  background-color: salmon;
}

.sortedTable .greenRow {
  background-color: green;
}

.sortedTable .grayRow {
  background-color: #25a25a;
}
.pagination .fields {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  height: 100%;
}

.pagination .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .pagination .fields {
    grid-template-columns: 1fr;
  }
}

.pages {
  display: inline-block;
}

.pages a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pages a.active {
  background-color: #4caf50;
  color: white;
}

.pages a:hover:not(.active) {
  background-color: #ddd;
}

.userList .userListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}
.zone-seperate {
  background:#25a25a;
  padding: 0.5vh;
  margin: 0 0.5vh;
}

.user-zones {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.user-zones .aa-small-box {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  background: #25a25a;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
  font-size: 13px;
  box-sizing: border-box;
}
.minHight-ul {
  min-width: 150px;
}

#add-vendor .add-vendor-form .fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height:100%;
}

#add-vendor .add-vendor-form .fields .field{
    padding-left: 10px;
    padding-right: 10px;

}

#add-vendor .add-vendor-form .text-area{
    margin-left: 10px;
}

@media  screen and (max-width:800px){
    #add-vendor .add-vendor-form .fields{
        grid-template-columns: 1fr;
    }
}
.vendorList .fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.fields{
  margin-bottom:3vh;
}


.vendorList .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.vendorList .vendorListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

.vendorList .updateButton {
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .vendorList{
    overflow-x:hidden;
  }
  .vendorList .fields {
    grid-template-columns: 1fr;
  }
}

.aa-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50vh;
}
.aa-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 50vh;
}
.aa-field {
  max-width: 400px;
  
}
.aa-box {
  width: 76%;
  min-height: 20vh;
  border-radius: 4px;
  background: rgb(207, 207, 207);
  margin: 2vh 0;
  padding: 2vh 2%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}
.aa-small-box {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  background: #25a25a;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
}
.aa-small-highlight {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
  border: solid rgb(170, 255, 130) 2px;
  background: #28a745;
}
.aa-align-row {
  display: flex;
  flex-direction: row;
  margin: 0.5vh;
  height: 5vh;
  padding: 1vh 0;
  align-items: center;
}

.zone-align {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.zone-align .aa-small-box {
  padding: 2vh;
  width: 150px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  height: 5vh;
  background: #25a25a;
  cursor: pointer;
  border: none;
  margin: 1vh;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}
@media screen and (max-width: 950px) {
  .aa-row {
    flex-direction: column;
    min-height: 20vh;
  }
  .aa-box {
    width: 90%;
  }
}

#add-installation .add-installation-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

#add-installation .add-installation-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #add-installation .add-installation-form .fields {
    grid-template-columns: 1fr;
  }
}

.installationListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

#add-material .add-material-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

#add-material .add-material-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #add-material .add-material-form .fields {
    grid-template-columns: 1fr;
  }
}

.materialListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

#add-machinery .add-machinery-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

#add-machinery .add-machinery-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #add-machinery .add-machinery-form .fields {
    grid-template-columns: 1fr;
  }
}

.machineryListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

#project-list {
}
  
#project-list .project-list-table{
    width: 100%;
    overflow-y: scroll;
}

#project-list .action-buttons{
    display: grid;
    width: 120px;
    grid-template-columns: 40px 40px 40px;
    grid-row-gap: 10px;
}

#project-list input{
    min-width: 180px;
}
.pd-bg {
  display: flex;
  flex-direction: column;
  background: rgb(234, 240, 250);
  width: 100%;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}
.pd-sec1 {
  margin: 1%;
  width: 98%;
  min-height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.pd-sec1-in {
  width: 48%;
  min-height: 30vh;
}
.pd-sec2 {
  margin: 1%;
  width: 92%;
  align-self: center;
  color: rgb(8, 22, 59);
  padding: 0 2%;
  min-height: 20vh;
  padding-bottom: 2.5vh;
  background: rgb(217, 230, 248);
}
.pd-sec2 p {
  font-weight: 600;
}
.pd-button {
  width: 30%;
  align-self: center;
  height: 6vh;
  background: rgb(14, 70, 134);
  border: none;
  color: white;
  margin: 3vh;
  margin-bottom: 6vh;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
}
.pd-sec3-in {
  /* background:rgb(42, 91, 148); */
  background: rgb(85, 146, 214);
  margin: 1vh;
  display: flex;
  align-self: center;
  align-items: center;
  width: 94%;
  padding: 0 2%;
  height: 6vh;
  border-radius: 3px;
  flex-grow: 1;
  color: white;
}
.pd-sec3-in2 {
  margin: 1vh;
  display: flex;
  align-self: center;
  width: 94%;
  padding: 2%;
  border-radius: 3px;
  color: rgb(8, 22, 59);
  min-height: 23vh;
  background: white;
  position: relative;
}

.pd-sec3-in2 a {
  text-decoration: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.material-list {
  margin: 0.5%;
  padding: 0.5%;
  box-sizing: border-box;
}

.drawing-details {
  margin: 1vh;
  display: flex;
  align-self: center;
  width: 94%;
  padding: 2%;
  border-radius: 3px;
  color: rgb(8, 22, 59);
  min-height: 23vh;
  background: white;
  position: relative;
}

.drawing-details .drawing-grid {
  width: 100%;
  padding: 1.5%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 1vh;
}
@media screen and (max-width: 1000px) {
  .pd-sec1 {
    flex-direction: column;
  }
  .pd-sec1-in {
    width: 99%;
    align-self: center;
    margin: 0.5vh 0;
  }
  .pd-button {
    width: 50%;
  }
}

.pd-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.pd-textholder {
  width: 130px;
  min-width: 130px;
  padding: 2vh;
  margin-top: 1.5vh;
  font-weight: 500;
  text-align: center;
  border-radius: 3px;
  color: white;
  background: rgb(43, 105, 175);
}
.pd-ans {
  padding: 1vh 2vh;
  margin: 1.5vh;
  margin-bottom: 0;
  min-height: 5vh;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  border-radius: 3px;
  font-weight: 500;
  color: rgb(3, 2, 49);
  background: rgb(180, 209, 248);
}
@media screen and (max-width: 1000px) {
  .pd-row {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .pd-textholder {
    width: 90%;
    text-align: start;
  }
  .pd-ans {
    width: 90%;
  }
}
@media screen and (max-width: 520px) {
  .pd-textholder {
    width: 90%;
    font-size: 13px;
  }
  .pd-ans {
    width: 90%;
    font-size: 13px;
  }
}


input[type="radio"],
input[type="checkbox"] {
  height: auto;
  width: auto;
  margin: 10px 10px 20px 0px;
  font-size: 12px;
}

label {
  margin-right: 20px;
  padding: 5px;
  vertical-align: baseline;
}

#add-doc .add-doc-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

#add-doc .add-doc-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #add-doc .add-doc-form .fields {
    grid-template-columns: 1fr;
  }
}

.installationListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

.showcause {
  display: flex;
  flex-direction: column;
  margin: 3vh 3%;
  padding: 3vh 3%;
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  width: 88%;
  min-height: 150vh;
  flex-grow: 1;
  font-size: 17px;
  font-weight: 400;
  align-items: center;
}
.jc-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1vh 0;
  font-size: 20px;
  min-height: 30vh;
  color: blak;
  align-items: flex-start;
  justify-content: flex-start;
}
.jc-p {
  font-size: 20px;
}
.sc-2 {
  text-decoration: underline;
  margin: 3vh;
}
.sc-lh {
  height: 14vh;
  width: auto;
}
.sc-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}
.sc-1 {
  width: 100%;
  margin: 0.5vh 0;
  display: flex;
  font-weight: 500;
  min-height: 5vh;
  font-size: 16px;
  align-items: center;
  padding: 2vh 0;
}
.sc-11 {
  justify-content: space-between;
}
.datediv {
  display: flex;
  align-items: center;
}
.lh-forms {
  width: 100%;
  height: auto;
}
.sc-3 {
  width: 100%;
  height: 1vh;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  display: flex;
  margin-bottom: 3vh;
  align-items: center;
  justify-content: space-between;
}
.sc-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 7vh;
  font-size: 20px;
  justify-content: space-between;
}
.sc-5 {
  width: 94%;
  margin: 0.5vh 1vh;
  display: flex;
  flex-direction: column;
  min-height: 5vh;
  font-size: 16px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2vh 3%;
}
.sc-5 p {
  margin: 0.5vh 0;
}
@media screen and (max-width: 1000px) {
  .sc-lh {
    height: 10vh;
    width: auto;
  }
  .showcause {
    font-size: 14px;
  }
  .sc-1 {
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .sc-col {
    font-size: 16px;
  }
  .sc-5 {
    font-size: 14px;
  }
  .show-cause-text {
    width: 90%;
  }
  .sc-button Button {
    margin-top: 4vh;
  }
  .datediv {
    width: 99.5%;
    margin-left: -2%;
  }
}
@media screen and (max-width: 600px) {
  .sc-lh {
    height: 7vh;
    width: auto;
  }
  .showcause {
    font-size: 14px;
    min-height: 120vh;
    /* margin:1vh 2%; */
    margin: 0;
    padding: 1vh 2%;
    width: 96%;
  }
  .sc-1 {
    font-size: 14px;
  }
  .sc-col {
    font-size: 14px;
    height: 5vh;
  }
  .sc-5 {
    font-size: 14px;
  }
  .sc-3 {
    height: 0.3vh;
    margin-bottom: 1vh;
  }
}


#add-user .add-user-form .warning-container {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left:12px;
}
#add-user .add-user-form .warning-container .warning{
    color:red;
    padding-left: 12px;
    padding-bottom: 5px;
    font-weight: 300;
}
.existing-arz{
    color:red;
    padding-top: 10px;
    padding-left:12px;
    padding-bottom: 5px;
}
.existingAreas, .existingZones{
    padding-left: 12px;
    padding-bottom: 10px;
}
#reports{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
}

#reports a{
    color: unset;
    text-decoration: unset;
}
#reports .report{
    min-height: 70px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(60,60,60,0.4);
    transition-duration: 0.4s;
    padding: 15px;
    box-sizing: border-box;
    border-left: 3px solid #1abc9c;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

#reports .report:hover{
    box-shadow: 0 0 10px rgba(60,60,60,0.2);
}

#reports .report h3{
    margin: 0;
    font-weight: 400;
}

#reports .report P{
    font-size: 0.9rem;
}

@media screen and (max-width:900px){
    #reports{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}
@media screen and (max-width:730px){
    #reports{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }   
}
@media screen and (max-width:450px){
    #reports{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
}
#vendor-jobs .vendor-jobs-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

#vendor-jobs .vendor-jobs-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #vendor-jobs .vendor-jobs-form .fields {
    grid-template-columns: 1fr;
  }
}

#user-jobs .user-jobs-form .fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
}

#user-jobs .user-jobs-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  #user-jobs .user-jobs-form .fields {
    grid-template-columns: 1fr;
  }
}


#project-list .project-list-table {
  width: 100%;
  overflow-y: scroll;
}

#project-list .action-buttons {
  display: grid;
  width: 120px;
  grid-template-columns: 40px 40px 40px;
  grid-row-gap: 10px;
}

#project-list input {
  min-width: 180px;
}

#project-list {
}

#project-list .project-list-table {
  width: 100%;
  overflow-y: scroll;
}

#project-list .action-buttons {
  display: grid;
  width: 120px;
  grid-template-columns: 40px 40px 40px;
  grid-row-gap: 10px;
}

#project-list input {
  min-width: 180px;
}

#project-list {
}

#project-list .project-list-table {
  width: 100%;
  overflow-y: scroll;
}

#project-list .action-buttons {
  display: grid;
  width: 120px;
  grid-template-columns: 40px 40px 40px;
  grid-row-gap: 10px;
}

#project-list input {
  min-width: 180px;
}

.JEselect{
    width:40%;
    min-width:300px;
}
@media screen and (max-width:600px){
    .JEselect{
        width:100%;
        min-width:100px;
    }  
}
.add-godown .fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}

.add-godown-form .fields .field{
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
.main-ims {
  min-height: 100vh;
  width: 100%;
  background-color: #e9e9e9;
}

.ims-godown-holder {
  background-color: white;
  border: 3px solid #1abc9c;
  width: 100%;
  min-height: 200px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 2;
  padding: 20px;
  box-sizing: border-box;
}

.ims-godown-holder-tabs {
  margin-left: 20px;
  margin-bottom: -2px;
}

.ims-entry-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 20px;
}

@media only screen and (max-width: 800px) {
  .ims-entry-form {
    grid-template-columns: 1fr;
  }
}

.ims-entry-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 20px;
}

@media only screen and (max-width: 800px) {
  .ims-entry-form {
    grid-template-columns: 1fr;
  }
}

#tally-book .project-details{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#tally-book .daily-progress-form{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#tally-book .daily-progress-form .field{
    padding-right: 20px;
}
#tally-book .machinery-user-form{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
#tally-book .material-user-form{
    display: grid;
    grid-template-columns: 1fr 2fr;
}
@media screen and (max-width:650px){
    #tally-book .project-details{
        grid-template-columns: 1fr;
    }
    
    #tally-book .daily-progress-form{
        grid-template-columns: 1fr;
    }
}
.unsortedTable,
th,
td {
  font-size: 1.1rem;
  table-layout: auto;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  padding: 15px;
}

.unsortedTable-header {
  border-bottom: 2px solid #e0e0e0;
}

.blankTable,
th,
td {
  table-layout: auto;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  padding: 15px;
}

#an-col{
    display:flex;
    flex-direction:column;
    width:100%;
    align-self:center;
    min-height:30vh;
}
.an-row{
    display:flex;
    align-items:center;
}
.an-row-2{
    display:flex;
    padding-top:3vh;
    border-top:solid 1px rgba(185, 185, 185, 0.548);
    align-items:center;
}
.an-row-in{
    width:100%;margin:0 2vh;
}
@media screen and (max-width:1000px){
    .an-row{
        display:flex;
        flex-direction:column;
    }
}
.notificationList .notificationListTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

#add-project .add-project-grid1 .fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
}

#add-project .add-project-grid2 .fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 100%;
}

#add-project .add-project-form .fields .field {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  #add-project .add-project-grid1 .fields,
  #add-project .add-project-grid2 .fields {
    grid-template-columns: 1fr;
  }
}

#an-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  min-height: 30vh;
}
.an-row {
  display: flex;
  align-items: center;
}
.an-row-2 {
  display: flex;
  padding-top: 3vh;
  border-top: solid 1px rgba(185, 185, 185, 0.548);
  align-items: center;
}
.an-row-in {
  width: 100%;
  margin: 0 2vh;
}
@media screen and (max-width: 1000px) {
  .an-row {
    display: flex;
    flex-direction: column;
  }
}

.wo-static{
    width:98%;
    margin-bottom:2vh;
    padding:2vh 1%;
    min-height:0vh;
}
.wo-center{
    display:flex;
    width:50%;
    height:5vh;
    margin:0 25%;
    align-items:center;
    justify-content:center;
}
#wo-border{
    border-top: solid 1px rgba(187, 187, 187, 0.568);
}
@media screen and (max-width:1000px){
    .wo-space{
     height:10vh;
     width:100%;
    }
    

}

.materialIssue .materialForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.materialIssue .materialForm .formField {
  padding: 0 10px;
  box-sizing: border-box;
}

.addMaterialForm {
  margin-top: 2vh;
}

.materialIssue .addMaterialForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.materialIssue .addMaterialForm .field {
  padding: 0 10px;
  box-sizing: border-box;
}

.materialIssue .materialTable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}

@media screen and (max-width: 800px) {
  .materialIssue .materialForm {
    grid-template-columns: 1fr;
  }
  .materialIssue .addMaterialForm {
    grid-template-columns: 1fr;
  }
}

.dailyprogress .dailytable {
  width: 100%;
  margin: auto;
  overflow-x: scroll;
}


#change-password .fields{
   display: flex;
   flex-direction: row;
}

#change-password .fields .field{
    
    padding-right: 30px;
    padding-bottom: 30px;
}

@media screen and (max-width: 600px){

#change-password .fields{
    flex-direction: column;
 }
}
@media screen and (max-width: 1000px) {
  .job-complete-res {
    display: grid;
    grid-template-columns: 1fr;
  }
  .jc-col {
    width: 90%;
    margin-left: 5%;
  }
  .sc-button Button {
    margin-top: 4vh;
  }
  .job-certificate-edit {
    margin-top: 4vh;
  }
}

#project-list {
}

#project-list .project-list-table {
  width: 100%;
  overflow-y: scroll;
}

#project-list .action-buttons {
  display: grid;
  width: 120px;
  grid-template-columns: 40px 40px 40px;
  grid-row-gap: 10px;
}

#project-list input {
  min-width: 180px;
}

#project-list {
}

#project-list .project-list-table {
  width: 100%;
  overflow-y: scroll;
}

#project-list .action-buttons {
  display: grid;
  width: 120px;
  grid-template-columns: 40px 40px 40px;
  grid-row-gap: 10px;
}

#project-list input {
  min-width: 180px;
}

.notificationList .notificationListTable {
    width: 100%;
    margin: auto;
    overflow-x: scroll;
  }
  
#an-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  min-height: 30vh;
}
.an-row {
  display: flex;
  align-items: center;
}
.an-row-2 {
  display: flex;
  padding-top: 3vh;
  border-top: solid 1px rgba(185, 185, 185, 0.548);
  align-items: center;
}
.an-row-in {
  width: 100%;
  margin: 0 2vh;
}
@media screen and (max-width: 1000px) {
  .an-row {
    display: flex;
    flex-direction: column;
  }
}

